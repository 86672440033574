<app-nav></app-nav>
<div class="has-offset-for-menu text-content">
  <app-c02-c07-text
    [compNumber]="17"
    [headlineLevel]="1"
    [content]="introduction"
    [headline]="'Hellooo!'"
    ><ng-template #introduction
      >Ich bin Anne, staatlich geprüfte Grafik Designerin und ich habe meinen Bachelor of Arts in Kommunikationsgestaltung an der HfG Schwäbisch Gmünd absolviert.</ng-template
    ></app-c02-c07-text
  >
  <app-c01-and-c10-and-c13-image
    [compNumber]="17"
    [title]="'Anne Frauendorf'"
    [imagePath]="'/assets/about-me/Portrait.jpg'"
  ></app-c01-and-c10-and-c13-image>

  <div class="row has-side-paddings about-me-content">
    <div class="col-12 col-md-8 col-lg-3">
      <h2>Was ich bisher gemacht habe</h2>
      Abschluss der mittleren Reife in Offenburg 2014 <br /><br />
      Abgeschlossene dreijährige Berufsausbildung zur staatlich geprüften
      Grafik-Designerin mit Fachhochschulreife 2017 <br /><br />Praktikum bei
      Andreas Anselm Grafik Design GmbH in Offenburg 2017
      <br /><br />Praxissemester bei Jürgen x. Albrecht - Büro für Konzeption &
      Design in Offenburg 2020<br /><br />
	  Abgeschlossenes Studium (B. A.) in Kommunikationsdesign an der HfG Schwäbisch Gmünd seit Juli 2021
	  <br /><br />
	  Freiberufliche Tätigkeit bei Jürgen x. Albrecht - Büro für Konzeption & Design in Offenburg seit August 2020
    </div>
    <div class="d-none d-lg-block col-1"></div>
    <div class="col-12 col-md-8 col-lg-3">
      <h2>Meine aktuellen Tätigkeiten</h2>
      Grafik-und UX Designerin bei Conversion Maker in Offenburg seit September 2021
    </div>
    <div class="d-none d-lg-block col-1"></div>
    <div class="col-12 col-md-8 col-lg-4">
      <h2>Mit was ich arbeite</h2>
      <div class="row no-gutters">
        <div class="col-12 col-md-6 col-lg-12 col-xl-6">
          <h3>Visualisierung</h3>
          Adobe Illustrator<br />
          Adobe InDesign<br />
          Adobe Photoshop <br />Adobe Lightroom <br />Cinema 4D<br />
          Handskizzen
          <br />
          <br />
        </div>
        <div class="col-12 col-md-6 col-lg-12 col-xl-6">
          <h3>Animation | Film</h3>
          Adobe After Effects <br />Adobe Premiere<br />
          Adobe XD <br />Tumult Hype <br />Figma <br />Sketch <br />Keynote
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
