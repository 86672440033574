<app-nav></app-nav>
<div
  class="has-side-paddings has-offset-for-menu text-content col-md-9 col-lg-7"
>
  <h2>Impressum</h2>
  Informationspflicht laut § 5 TMG.

  <h3>Kontakt</h3>
  <p>
    Anne Frauendorf <br />Veilchenweg 1,<br />
    77731 Willstätt, Deutschland<br />
    E-Mail: info@anne-frauendorf-design.com
  </p>
  <p class="developer">Made with ❤️ in Konstanz.</p>
  <h3>Haftung für Inhalte dieser Website</h3>
  <p>
    Wir entwickeln die Inhalte dieser Webseite ständig weiter und bemühen uns
    korrekte und aktuelle Informationen bereitzustellen. Laut Telemediengesetz
    (TMG) §7 (1) sind wir als Diensteanbieter für eigene Informationen, die wir
    zur Nutzung bereitstellen, nach den allgemeinen Gesetzen verantwortlich.
    Leider können wir keine Haftung für die Korrektheit aller Inhalte auf dieser
    Webseite übernehmen, speziell für jene die seitens Dritter bereitgestellt
    wurden. Als Diensteanbieter im Sinne der §§ 8 bis 10 sind wir nicht
    verpflichtet, die von ihnen übermittelten oder gespeicherten Informationen
    zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige
    Tätigkeit hinweisen. Unsere Verpflichtungen zur Entfernung von Informationen
    oder zur Sperrung der Nutzung von Informationen nach den allgemeinen
    Gesetzen aufgrund von gerichtlichen oder behördlichen Anordnungen bleiben
    auch im Falle unserer Nichtverantwortlichkeit nach den §§ 8 bis 10
    unberührt. Sollten Ihnen problematische oder rechtswidrige Inhalte
    auffallen, bitte wir Sie uns umgehend zu kontaktieren, damit wir die
    rechtswidrigen Inhalte entfernen können. Sie finden die Kontaktdaten im
    Impressum.
  </p>

  <h3>Haftung für Links auf dieser Website</h3>
  <p>
    Unsere Webseite enthält Links zu anderen Webseiten für deren Inhalt wir
    nicht verantwortlich sind. Haftung für verlinkte Websites besteht für uns
    nicht, da wir keine Kenntnis rechtswidriger Tätigkeiten hatten und haben,
    uns solche Rechtswidrigkeiten auch bisher nicht aufgefallen sind und wir
    Links sofort entfernen würden, wenn uns Rechtswidrigkeiten bekannt werden.
    Wenn Ihnen rechtswidrige Links auf unserer Website auffallen, bitte wir Sie
    uns zu kontaktieren. Sie finden die Kontaktdaten im Impressum.
  </p>

  <h3>Urheberrechtshinweis</h3>
  <p>
    Alle Inhalte dieser Webseite (Bilder, Fotos, Texte, Videos) unterliegen dem
    Urheberrecht der Bundesrepublik Deutschland. Bitte fragen Sie uns bevor Sie
    die Inhalte dieser Website verbreiten, vervielfältigen oder verwerten wie
    zum Beispiel auf anderen Websites erneut veröffentlichen. Falls notwendig,
    werden wir die unerlaubte Nutzung von Teilen der Inhalte unserer Seite
    rechtlich verfolgen. Sollten Sie auf dieser Webseite Inhalte finden, die das
    Urheberrecht verletzen, bitten wir Sie uns zu kontaktieren.
  </p>

  <h3>Bildernachweis</h3>
  <p>
    Die Bilder, Fotos und Grafiken auf dieser Webseite sind urheberrechtlich
    geschützt.
    <br />
    Quelle: Erstellt mit dem Impressum Generator von AdSimple in Kooperation mit
    warkly.de
  </p>
</div>
<app-footer></app-footer>
