<ng-container *ngIf="images && images.length > 0 && images[0] !== ''">
  <ng-template #imageContent>
    <div class="carousel-cell" *ngFor="let image of images">
      <img [src]="image" />
    </div>
  </ng-template>
  <div class="has-side-paddings carousel-container">
    <carousel
      [cellsToShow]="1"
      [loop]="true"
      class="custom-carousel show-one d-lg-none"
    >
      <ng-container *ngTemplateOutlet="imageContent"></ng-container>
    </carousel>
    <carousel
      [cellsToShow]="2"
      [loop]="true"
      class="custom-carousel show-two d-none d-lg-block"
    >
      <ng-container *ngTemplateOutlet="imageContent"></ng-container>
    </carousel>
  </div>
</ng-container>
