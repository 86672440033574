import { ColConfig } from '../../models/col-config.model';
const c01Config: ColConfig = {
  smartphone: {
    nCols: 12,
    colsShift: 0,
  },
  tabletPortrait: {
    nCols: 12,
    colsShift: 0,
  },
  tabletLandscape: {
    nCols: 12,
    colsShift: 0,
  },
  desktop: {
    nCols: 12,
    colsShift: 0,
  },
};

const c10Config: ColConfig = {
  smartphone: {
    nCols: 12,
    colsShift: 0,
  },
  tabletPortrait: {
    nCols: 8,
    colsShift: 2,
  },
  tabletLandscape: {
    nCols: 8,
    colsShift: 2,
  },
  desktop: {
    nCols: 8,
    colsShift: 2,
  },
};

const c12Config: ColConfig = {
  smartphone: {
    nCols: 12,
    colsShift: 0,
  },
  tabletPortrait: {
    nCols: 12,
    colsShift: 0,
  },
  tabletLandscape: {
    nCols: 10,
    colsShift: 1,
  },
  desktop: {
    nCols: 10,
    colsShift: 1,
  },
};

const c13Config: ColConfig = {
  smartphone: {
    nCols: 12,
    colsShift: 0,
  },
  tabletPortrait: {
    nCols: 12,
    colsShift: 0,
  },
  tabletLandscape: {
    nCols: 10,
    colsShift: 1,
  },
  desktop: {
    nCols: 12,
    colsShift: 0,
  },
};

const c15Config: ColConfig = {
  smartphone: {
    nCols: 12,
    colsShift: 0,
  },
  tabletPortrait: {
    nCols: 8,
    colsShift: 0,
  },
  tabletLandscape: {
    nCols: 6,
    colsShift: 0,
  },
  desktop: {
    nCols: 6,
    colsShift: 0,
  },
};

const c17Config: ColConfig = {
  smartphone: {
    nCols: 12,
    colsShift: 0,
  },
  tabletPortrait: {
    nCols: 8,
    colsShift: 2,
  },
  tabletLandscape: {
    nCols: 6,
    colsShift: 3,
  },
  desktop: {
    nCols: 6,
    colsShift: 3,
  },
};

export const imageComponentColConfig: { [key: number]: ColConfig } = {
  1: c01Config,
  10: c10Config,
  12: c12Config,
  13: c13Config,
  15: c15Config,
  17: c17Config, // about me page
};
