<ng-template #logo><span class="logo">Anne Frauendorf.</span></ng-template>
<ng-template #mobileNavContainer
  ><span class="logo">
    <div class="nav-container mobile d-lg-none has-side-paddings d-flex">
      <a class="logo-container" routerLink="">
        <ng-container *ngTemplateOutlet="logo"></ng-container>
      </a>

      <div
        class="burger burger-rotate burger-right"
        [ngClass]="open ? 'open' : ''"
        (click)="clickEvent()"
      >
        <div class="burger-lines"></div>
      </div></div></span
></ng-template>
<div id="top"></div>
<ng-container *ngTemplateOutlet="mobileNavContainer"></ng-container>

<div class="mobile-menu d-lg-none" [ngClass]="open ? 'open' : 'closed'">
  <ng-container *ngTemplateOutlet="mobileNavContainer"></ng-container>
  <ul class="nav-list">
    <li><a [routerLink]="ls.formatLink(ls.linkNames.home)">Home</a></li>
    <li>
      <a [routerLink]="ls.formatLink(ls.linkNames.aboutMe)">Über Mich</a>
    </li>
    <li>
      <a [routerLink]="ls.formatLink(ls.linkNames.contact)">Kontakt</a>
    </li>
    <li>
      <a [routerLink]="ls.formatLink(ls.linkNames.imprint)">Impressum</a>
    </li>
    <li>
      <a [routerLink]="ls.formatLink(ls.linkNames.privacy)">Datenschutz</a>
    </li>
  </ul>
</div>

<div
  class="nav-container has-side-paddings desktop d-none d-lg-flex"
  [ngClass]="{ 'is-white': isWhite }"
>
  <a class="logo-container" routerLink="">
    <ng-container *ngTemplateOutlet="logo"></ng-container>
  </a>
  <div class="d-flex align-items-center">
    <a
      class="navigation-link"
      [routerLink]="ls.formatLink(ls.linkNames.aboutMe)"
      routerLinkActive="active"
      >Über Mich</a
    >
    <a
      class="navigation-link"
      [routerLink]="ls.formatLink(ls.linkNames.contact)"
      routerLinkActive="active"
      >Kontakt</a
    >
  </div>
</div>
