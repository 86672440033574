import { Component, OnInit } from '@angular/core';
import { ProjectBaseComponent } from '../project-base/project-base.component';
import { ProjectBaseInterface } from '../project-base/project-base.interface';
import { Router } from '@angular/router';

@Component({
  selector: 'app-prevention',
  templateUrl: './prevention.component.html',
  styleUrls: ['./prevention.component.scss'],
})
export class PreventionComponent
  extends ProjectBaseComponent
  implements OnInit, ProjectBaseInterface {
  title = 'Pille, Kondom oder';

  constructor(router: Router) {
    super(router);
  }

  ngOnInit(): void {}
}
