import { Component, Input, OnInit } from '@angular/core';
import { ProjectBaseComponent } from '../project-base/project-base.component';
import { ProjectBaseInterface } from '../project-base/project-base.interface';
import { Router } from '@angular/router';

@Component({
  selector: 'app-rethinking-wikipedia',
  templateUrl: './rethinking-wikipedia.component.html',
  styleUrls: ['./rethinking-wikipedia.component.scss'],
})
export class RethinkingWikipediaComponent
  extends ProjectBaseComponent
  implements OnInit, ProjectBaseInterface {
  title = 'Rethink Wikipedia';
  constructor(router: Router) {
    super(router);
  }

  ngOnInit(): void {}
}
