<app-project-header
  [imagePath]="'/assets/03_Audiovisuelle-Gestaltung/Sources/01_2400x1500px.jpg'"
  [title]="title"
  [isWhite]="true"
></app-project-header>
<app-c16-spacing></app-c16-spacing>
<app-c02-c07-text
  [headline]="'No Money – KUSO GVKI<br>Audiovisuelle Gestaltung'"
  [headlineLevel]="1"
  [content]="noMoneyIntroContent"
  [compNumber]="3"
  ><ng-template #noMoneyIntroContent
    >Die Musik ist ein Zusammenspiel aus vielen Elementen, die bei dem Hörer
    Gefühle, Emotionen oder Assoziationen hervorrufen. Diese lassen sich sehr
    gut visuell interpretieren und darstellen. Das Musikstück „No Money“ von
    KUSO GVKI wurde zuerst in seine einzelnen Instrumente zerlegt und
    charakterisiert. Die charakteristischen Eigenschaften wie Töne, Lautstärke,
    Rhythmik und Emotionen der Musik wurden anschließend analysiert und danach
    visualisiert. Mithilfe von runden Formen entstand letztendlich ein
    animiertes Musikvideo, welches auf eine 3D-Konstruktion übertragen werden
    konnte.
  </ng-template>
</app-c02-c07-text>
<app-c16-spacing></app-c16-spacing>
<app-video [key]="'512262232'" [compNumber]="12"></app-video>
<app-c16-spacing></app-c16-spacing>
<app-c08-c09-image-with-text
  [headline]="'Gestaltungsprozess und Storyboard'"
  [content]="content2"
  [textFirst]="true"
  [title]="title"
  [imagePath]="'/assets/03_Audiovisuelle-Gestaltung/Sources/03.jpg'"
  ><ng-template #content2
    >Bis zur finalen Visualisierung wurde das Musikstück bis ins kleinste Detail
    ausgewertet. Dazu wurden verschiedene Methoden, wie morphologische Kästen
    und Tonspuranalyse angefertigt.<br /><br />
    Mithilfe von erstellten Storyboards konnte ein genauer Ablauf und die
    Platzierung der Instrumente bestimmt werden. Dies war wichtig, um jede
    Sekunde des Songs punktgenau zu animieren.<br /><br />Teampartner: Seraphina
    Schidlo, Vincent Seitz</ng-template
  >
</app-c08-c09-image-with-text>
<app-c16-spacing></app-c16-spacing>

<app-c01-and-c10-and-c13-image
  [imagePath]="'/assets/03_Audiovisuelle-Gestaltung/Sources/04.jpg'"
  [title]="title"
  [compNumber]="13"
></app-c01-and-c10-and-c13-image>
<app-c01-and-c10-and-c13-image
  [imagePath]="'/assets/03_Audiovisuelle-Gestaltung/Sources/05.png'"
  [title]="title"
  [compNumber]="10"
></app-c01-and-c10-and-c13-image>
<app-c01-and-c10-and-c13-image
  [imagePath]="'/assets/03_Audiovisuelle-Gestaltung/Sources/06.jpg'"
  [title]="title"
  [compNumber]="13"
></app-c01-and-c10-and-c13-image>
<app-c01-and-c10-and-c13-image
  [imagePath]="'/assets/03_Audiovisuelle-Gestaltung/Sources/07.jpg'"
  [title]="title"
  [compNumber]="13"
></app-c01-and-c10-and-c13-image>
<app-c01-and-c10-and-c13-image
  [imagePath]="'/assets/03_Audiovisuelle-Gestaltung/Sources/08.jpg'"
  [title]="title"
  [compNumber]="13"
></app-c01-and-c10-and-c13-image>
<app-c01-and-c10-and-c13-image
  [imagePath]="'/assets/03_Audiovisuelle-Gestaltung/Sources/09.jpg'"
  [title]="title"
  [compNumber]="13"
></app-c01-and-c10-and-c13-image>
<app-c01-and-c10-and-c13-image
  [imagePath]="'/assets/03_Audiovisuelle-Gestaltung/Sources/10.jpg'"
  [title]="title"
  [compNumber]="13"
></app-c01-and-c10-and-c13-image>

<app-footer></app-footer>
