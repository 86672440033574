import { Injectable } from '@angular/core';
import { linkNames, projectPages } from '../app-routing.module';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LinkService {
  public linkNames = linkNames;
  public projectPages = projectPages;

  // @ts-ignore
  private isProjectPageSubject: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );
  public readonly isProjectPage$: Observable<boolean> = this.isProjectPageSubject.asObservable();

  private nextProjectPageSubject: BehaviorSubject<string> = new BehaviorSubject(
    ''
  );
  public readonly nextProjectPage$: Observable<string> = this.nextProjectPageSubject.asObservable();

  private previousProjectPageSubject: BehaviorSubject<string> = new BehaviorSubject(
    ''
  );
  public readonly previousProjectPage$: Observable<string> = this.previousProjectPageSubject.asObservable();

  url = '';

  constructor(router: Router) {
    router.events
      .pipe(filter((event: any) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.url = event.url.substring(1);
        const pageId = this.projectPageId(this.url);
        this.isProjectPageSubject.next(this.isProjectPage(this.url));
        this.previousProjectPageSubject.next(
          this.projectPages[this.previousProjectPageIndex(pageId)]
        );
        this.nextProjectPageSubject.next(
          this.projectPages[this.nextProjectPageIndex(pageId)]
        );
      });
  }

  public formatLink(link: string | null): string {
    return '/' + link;
  }

  projectPageId(url: string): number {
    return this.projectPages.findIndex((cUrl) => url === cUrl);
  }

  isProjectPage(url: string): boolean {
    return this.projectPageId(url) !== -1;
  }

  nextProjectPageIndex(index: number): number {
    if (index === this.projectPages.length - 1) {
      return 0;
    } else {
      return ++index;
    }
  }

  previousProjectPageIndex(index: number): number {
    if (index === 0) {
      return this.projectPages.length - 1;
    } else {
      return --index;
    }
  }
}
