<app-project-header
  [imagePath]="'/assets/07_Dropbox-Redesign/Sources/01_2400x1500px.jpg'"
  [title]="title"
></app-project-header>
<app-c16-spacing></app-c16-spacing>
<app-c02-c07-text
  [headline]="'New Dropbox'"
  [headlineLevel]="1"
  [content]="content1"
  [compNumber]="3"
  ><ng-template #content1
    >Das Kurzprojekt zeigt ein Redesign der Dropbox, bei dem unvorteilhafte
    Bedienelemente verbessert oder neues hinzufügt wurde, um die App
    nutzerfreundlicher zu machen. Dafür wurden die App zuerst im Detail
    getestet, die Probleme notiert und die Bedürfnisse der Benutzer*innen
    definiert. Auch die optische Aufmachung wurde zur Verbesserung
    angepasst.</ng-template
  >
</app-c02-c07-text>
<app-c16-spacing></app-c16-spacing>
<app-c01-and-c10-and-c13-image
  [imagePath]="'/assets/07_Dropbox-Redesign/Sources/02.jpg'"
  [title]="title"
  [compNumber]="13"
></app-c01-and-c10-and-c13-image>

<app-c16-spacing></app-c16-spacing>

<app-c08-c09-image-with-text
  [headline]="'Die Umsetzung'"
  [content]="content2"
  [textFirst]="false"
  [title]="title"
  [imagePath]="'/assets/07_Dropbox-Redesign/Sources/03.jpg'"
  ><ng-template #content2
    >Ein Grund, warum wir uns für ein Redesign der App entschieden haben, ist
    die Ordnerstruktur, die besonders neuen Benutzer*innen Probleme bereitet.
    Die Unterscheiden zwischen eigenen und freigegebenen Ordnern ist nicht
    sofort ersichtlich, was zum Beispiel durch verschiedene Farben gelöst werden
    kann. Zusätzlich wurde die Speicherleiste hervorgehoben, da viele
    Benutzer*innen der Dropbox Free Version wissen wollen, wie viel
    Speicherplatz sie noch haben.</ng-template
  ></app-c08-c09-image-with-text
>

<app-c16-spacing></app-c16-spacing>
<app-c01-and-c10-and-c13-image
  [imagePath]="'/assets/07_Dropbox-Redesign/Sources/04.jpg'"
  [title]="title"
  [compNumber]="1"
></app-c01-and-c10-and-c13-image>
<app-c16-spacing></app-c16-spacing>
<app-c02-c07-text
  [headline]="'Das finale Ergebnis des Redesigns'"
  [headlineLevel]="2"
  [content]="content3"
  [compNumber]="2"
  ><ng-template #content3
    >Neben den verbesserten Features bekommt die App zu guter Letzt auch einen
    dunklen Modus, der Nutzer*innen mehr Freiheiten im Design bietet und die
    Augen beim Nutzen der App entlastet.<br /><br />Team: Lisa Amann, Aileen
    Gedrat und Nikola John</ng-template
  >
</app-c02-c07-text>
<app-c16-spacing></app-c16-spacing>
<app-video [key]="'512261982'" [compNumber]="12"></app-video>
<app-footer></app-footer>
