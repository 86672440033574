<div class="has-side-paddings">
  <div class="row align-items-center">
    <!--    Text first-->
    <div
      class="col-12 col-md-9 col-lg-5 has-top-bottom-spacing"
      *ngIf="textFirst"
    >
      <app-text-base
        [headline]="headline"
        [nCols]="12"
        [content]="content"
      ></app-text-base>
    </div>
    <div class="col-1" *ngIf="textFirst"></div>
    <div class="col-12 col-md-8 col-lg-6 has-top-bottom-spacing">
      <app-image-base [imagePath]="imagePath" [title]="title" [nCols]="12">
      </app-image-base>
    </div>
    <div class="col-1" *ngIf="!textFirst"></div>
    <div
      class="col-12 col-md-9 col-lg-5 has-top-bottom-spacing"
      *ngIf="!textFirst"
    >
      <app-text-base
        [headline]="headline"
        [nCols]="12"
        [content]="content"
      ></app-text-base>
    </div>
  </div>
</div>
