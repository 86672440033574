<!--Component 1 is special since it does not need row paddings-->
<div *ngIf="compNumber === 1">
  <app-custom-img-tag
    [imagePath]="imagePath"
    [title]="title"
  ></app-custom-img-tag>
</div>

<div
  *ngIf="compNumber !== 1"
  [ngClass]="{
    'has-side-paddings': hasSidePaddings,
    'has-top-bottom-spacing': hasTopBottomSpacing
  }"
>
  <!-- =< small: Smartphone -->
  <div class="d-md-none">
    <app-image-base
      [nCols]="colConfig.smartphone.nCols"
      [nColsShift]="colConfig.smartphone.colsShift"
      [title]="title"
      [imagePath]="imagePath"
    >
    </app-image-base>
  </div>

  <!-- medium: Tablet Portrait -->
  <div class="d-none d-md-block d-lg-none">
    <app-image-base
      [nCols]="colConfig.tabletPortrait.nCols"
      [nColsShift]="colConfig.tabletPortrait.colsShift"
      [title]="title"
      [imagePath]="imagePath"
    >
    </app-image-base>
  </div>

  <!-- large: Tablet Landscape -->
  <div class="d-none d-lg-block d-xl-none">
    <app-image-base
      [nCols]="colConfig.tabletLandscape.nCols"
      [nColsShift]="colConfig.tabletLandscape.colsShift"
      [title]="title"
      [imagePath]="imagePath"
    >
    </app-image-base>
  </div>

  <!-- >= large: Desktop -->
  <div class="d-none d-xl-block">
    <app-image-base
      [nCols]="colConfig.desktop.nCols"
      [nColsShift]="colConfig.desktop.colsShift"
      [title]="title"
      [imagePath]="imagePath"
    >
    </app-image-base>
  </div>
</div>
