import { ColConfig } from '../../models/col-config.model';
const c02Config: ColConfig = {
  smartphone: {
    nCols: 12,
    colsShift: 0,
  },
  tabletPortrait: {
    nCols: 10,
    colsShift: 1,
  },
  tabletLandscape: {
    nCols: 7,
    colsShift: 2,
  },
  desktop: {
    nCols: 5,
    colsShift: 3,
  },
};

const c03Config: ColConfig = {
  smartphone: {
    nCols: 12,
    colsShift: 0,
  },
  tabletPortrait: {
    nCols: 11,
    colsShift: 1,
  },
  tabletLandscape: {
    nCols: 8,
    colsShift: 2,
  },
  desktop: {
    nCols: 7,
    colsShift: 3,
  },
};

const c04Config: ColConfig = {
  smartphone: {
    nCols: 12,
    colsShift: 0,
  },
  tabletPortrait: {
    nCols: 9,
    colsShift: 0,
  },
  tabletLandscape: {
    nCols: 7,
    colsShift: 0,
  },
  desktop: {
    nCols: 6,
    colsShift: 0,
  },
};

const c05Config: ColConfig = {
  smartphone: {
    nCols: 12,
    colsShift: 0,
  },
  tabletPortrait: {
    nCols: 10,
    colsShift: 0,
  },
  tabletLandscape: {
    nCols: 8,
    colsShift: 0,
  },
  desktop: {
    nCols: 7,
    colsShift: 0,
  },
};

const c06Config: ColConfig = {
  smartphone: {
    nCols: 12,
    colsShift: 0,
  },
  tabletPortrait: {
    nCols: 9,
    colsShift: 3,
  },
  tabletLandscape: {
    nCols: 7,
    colsShift: 5,
  },
  desktop: {
    nCols: 5,
    colsShift: 7,
  },
};

const c07Config: ColConfig = {
  smartphone: {
    nCols: 12,
    colsShift: 0,
  },
  tabletPortrait: {
    nCols: 10,
    colsShift: 2,
  },
  tabletLandscape: {
    nCols: 8,
    colsShift: 4,
  },
  desktop: {
    nCols: 7,
    colsShift: 5,
  },
};

const c17Config: ColConfig = {
  smartphone: {
    nCols: 12,
    colsShift: 0,
  },
  tabletPortrait: {
    nCols: 8,
    colsShift: 2,
  },
  tabletLandscape: {
    nCols: 6,
    colsShift: 3,
  },
  desktop: {
    nCols: 6,
    colsShift: 3,
  },
};

export const componentColConfig: { [key: number]: ColConfig } = {
  2: c02Config,
  3: c03Config,
  4: c04Config,
  5: c05Config,
  6: c06Config,
  7: c07Config,
  17: c17Config,
};
