<!--<div-->
<!--  class="project-header-container"-->
<!--  [ngStyle]="{ 'background-image': 'url(' + imagePath + ')' }"-->
<!--&gt;</div>-->
<app-nav [isWhite]="isWhite"></app-nav>
<div class="margin-container d-lg-none"></div>
<app-c01-and-c10-and-c13-image
  [title]="title"
  [imagePath]="imagePath"
  [hasSidePaddings]="false"
  [hasTopBottomSpacing]="false"
  [compNumber]="1"
></app-c01-and-c10-and-c13-image>
