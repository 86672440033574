import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-c08-c09-image-with-text',
  templateUrl: './c08-c09-image-with-text.component.html',
  styleUrls: ['./c08-c09-image-with-text.component.scss'],
})
export class C08C09ImageWithTextComponent implements OnInit {
  @Input() textFirst = true;
  @Input() headline = '';
  @Input() imagePath = '';
  @Input() title = '';
  @Input() content: any;

  constructor() {}

  ngOnInit(): void {}
}
