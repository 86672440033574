<app-project-header
  [imagePath]="'/assets/10_bachelor/Sources/01_2400x1500px.jpg'"
  [title]="title"
></app-project-header>
<app-c16-spacing></app-c16-spacing>
<app-c02-c07-text
  [headline]="
    'Squared Eyes – Ein Ausstellungskonzept zur Informationsflut durch moderne Kommunikationsmittel'
  "
  [headlineLevel]="1"
  [content]="bachelor1Content"
  [compNumber]="3"
  ><ng-template #bachelor1Content
    >Die Digitalisierung hat unsere Welt stark verändert. Das mobile Internet
    ermöglicht es uns, fast immer und überall online zu sein. Besonders junge
    Menschen können sich ein Leben ohne ihr Smartphone kaum noch vorstellen.
    Dabei zählen die Kommunikation und die Nutzung sozialer Medien zu den
    beliebtesten Beschäftigungen. Wenn auch gleich diese zwei Faktoren die
    größten Zeitfresser in unserem Alltag sind. <br /><br />Die Ausstellung
    „Squared Eyes“ lädt Besucher*innen dazu ein, sich mit der Informationsflut
    durch moderne Kommunikationsmittel auseinander zu setzen. Neben der
    klassischen Wissensvermittlung greift das Ausstellungskonzept verschiedene
    Szenarien des alltäglichen Lebens auf, die im Inneren der einzelnen Module
    erfahrbar gemacht werden. Darüber hinaus ermöglichen kleine Mitmachstationen
    eine aktive Beteiligung an der Ausstellung. Für die Umsetzung des
    Ausstellungskonzeptes wurde das Modul „Always Online“ im Detail
    visualisiert.</ng-template
  >
</app-c02-c07-text>
<app-c16-spacing></app-c16-spacing>
<app-c01-and-c10-and-c13-image
  [imagePath]="'/assets/10_bachelor/Sources/02.jpg'"
  [title]="title"
  [compNumber]="10"
></app-c01-and-c10-and-c13-image>
<app-c16-spacing></app-c16-spacing>
<app-c16-spacing></app-c16-spacing>
<app-c08-c09-image-with-text
  [headline]="'Im Inneren des Moduls'"
  [content]="bachelor2Content"
  [textFirst]="true"
  [title]="title"
  [imagePath]="'/assets/10_bachelor/Sources/03.jpg'"
  ><ng-template #bachelor2Content
    >Ein inszenierter Bahnsteig konfrontiert die Besucher*innen mit dem Umgang
    von Smartphones in Wartesituationen. Der Einstieg erfolgt über eine für
    einen Bahnhof typische Texttafel in Form eines Handyscreens.<br /><br />
    Im Inneren des Moduls veranschaulicht eine Videoanimation die Handynutzung
    unterwegs und gibt dabei einen Einblick in das jeweilige Smartphone. Richten
    die Besucher*innen ihren Blick nach links, sehen sie den Bahnaufgang am Ende
    des Gleises. Ein spielerisches Highlight der Station ist der Selfieautomat
    in Form eines Smartphones. Per Zufallsgenerator wird den Besucher*innen ein
    Online Charakter in Anlehnung an das Video zugeordnet.</ng-template
  ></app-c08-c09-image-with-text
>
<app-c16-spacing></app-c16-spacing>
<app-c01-and-c10-and-c13-image
  [imagePath]="'/assets/10_bachelor/Sources/04.jpg'"
  [title]="title"
  [compNumber]="10"
></app-c01-and-c10-and-c13-image>
<app-c16-spacing></app-c16-spacing>
<app-video [key]="'574423762'"></app-video>
<app-c16-spacing></app-c16-spacing>
<app-video [key]="'574430312'"></app-video>
<app-c16-spacing></app-c16-spacing>
<app-c16-spacing></app-c16-spacing>
<app-c08-c09-image-with-text
  [headline]="'Die Außenwände des Moduls'"
  [content]="bachelor3Content"
  [textFirst]="false"
  [title]="title"
  [imagePath]="'/assets/10_bachelor/Sources/05.jpg'"
  ><ng-template #bachelor3Content
    >Während im Inneren der Module das Erleben im Vordergrund steht, sollen die
    Außenwände in erster Linie Wissen vermitteln. Die Wand zeigt verschiedene
    Situationen des Alltags, in denen wir das Handy benutzen und soll zeigen,
    dass auch viele kleine Informationssnacks in der Summe eine Menge Zeit
    kosten. Um den Besucher*innen diese Problematik vor Augen zu führen, bietet
    das Modul „Always Online“ die Möglichkeit, die eigene Bildschirmzeit auf ein
    ganzes Jahr hochzurechnen. Das Ergebnis wird in Form eines gedruckten
    Kassenzettels festgehalten und kann als Erinnerung mit nach Hause genommen
    werden.</ng-template
  ></app-c08-c09-image-with-text
>
<app-c16-spacing></app-c16-spacing>
<app-c01-and-c10-and-c13-image
  [imagePath]="'/assets/10_bachelor/Sources/06.jpg'"
  [title]="title"
  [compNumber]="10"
></app-c01-and-c10-and-c13-image>
<app-c16-spacing></app-c16-spacing>
<app-video [key]="'574424245'"></app-video>
<app-c16-spacing></app-c16-spacing>
<app-c16-spacing></app-c16-spacing>
<app-c02-c07-text
  [headline]="'Was passiert eigentlich in einer Minute?'"
  [headlineLevel]="2"
  [content]="bachelor4Content"
  [compNumber]="5"
  ><ng-template #bachelor4Content
    >60 Sekunden vergehen schnell, aber in dieser Zeitspanne passiert im
    Internet eine Menge. Die Menschheit produziert heute in nur zwei Tagen so
    viele Informationen, wie in den letzten zwei Millionen Jahren insgesamt. Aus
    der Wand hervorstehende Bildschirme zeigen stellvertretend für diese großen
    Datenmengen die Anzahl der unterschiedlichen Ereignisse auf Instagram,
    WhatsApp und Co. in Echtzeit. Dabei werden die Daten der ganzen Welt
    erfasst.</ng-template
  >
</app-c02-c07-text>
<app-c16-spacing></app-c16-spacing>
<app-c14-two-images
  [hasSidePaddings]="true"
  [imagePath1]="'/assets/10_bachelor/Sources/07.jpg'"
  [title1]="title"
  [imagePath2]="'/assets/10_bachelor/Sources/08.jpg'"
  [title2]="title"
></app-c14-two-images>
<app-c16-spacing></app-c16-spacing>
<app-video [key]="'574424280'"></app-video>
<app-c16-spacing></app-c16-spacing>
<app-c16-spacing></app-c16-spacing>
<app-c02-c07-text
  [headline]="'Faltflyer als kleine Hilfestellung'"
  [headlineLevel]="2"
  [content]="bachelor5Content"
  [compNumber]="3"
  ><ng-template #bachelor5Content
    >Ein Give-Away in Form eines kleinen Faltflyers gibt den Besucher*innen 7
    Tipps und Tricks mit auf den Weg, die dabei helfen können, die
    Bildschirmzeit zu reduzieren. Ein kleines Highlight ist ein Sticker, der
    sich im Inneren des Flyers befindet. Diesen können die Betroffenen an ihrem
    Handy anbringen, um daran erinnert zu werden, keine viereckigen Augen zu
    bekommen. Die Take Home Message der Ausstellung lautet: Relevanzen
    überprüfen, um die Bildschirmzeit zu reduzieren und die neu gewonnene
    Freizeit in vollen Zügen zu genießen. <br /><br />Team der Bachelorthesis:
    Seraphina Schidlo</ng-template
  >
</app-c02-c07-text>
<app-c16-spacing></app-c16-spacing>
<app-c11-slider
  [images]="[
    '/assets/10_bachelor/Sources/09_Slider1.jpg',
    '/assets/10_bachelor/Sources/10_Slider2.jpg',
    '/assets/10_bachelor/Sources/11_Slider3.jpg',
    '/assets/10_bachelor/Sources/12_Slider4.jpg',
    '/assets/10_bachelor/Sources/13_Slider5.jpg',
    '/assets/10_bachelor/Sources/14_Slider6.jpg',
    '/assets/10_bachelor/Sources/15_Slider7.jpg'
  ]"
></app-c11-slider>
<app-footer></app-footer>
