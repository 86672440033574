import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { StartComponent } from './pages/start/start.component';
import { RethinkingWikipediaComponent } from './pages/rethinking-wikipedia/rethinking-wikipedia.component';
import { EndangeredSpeciesComponent } from './pages/endangered-species/endangered-species.component';
import { FreeSpaceComponent } from './pages/free-space/free-space.component';
import { NoMoneyComponent } from './pages/no-money/no-money.component';
import { PreventionComponent } from './pages/prevention/prevention.component';
import { StationaryWaveComponent } from './pages/stationary-wave/stationary-wave.component';
import { NewDropboxComponent } from './pages/new-dropbox/new-dropbox.component';
import { HelveticaComponent } from './pages/helvetica/helvetica.component';
import { ImmuneCompassComponent } from './pages/immune-compass/immune-compass.component';
import { AboutMeComponent } from './pages/about-me/about-me.component';
import { ContactComponent } from './pages/contact/contact.component';
import { ImprintComponent } from './pages/imprint/imprint.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';
import { BachelorComponent } from './pages/bachelor/bachelor.component';

export const linkNames = {
  home: '',
  aboutMe: 'ueber-mich',
  contact: 'kontakt',
  imprint: 'impressum',
  privacy: 'datenschutz',
  rethinkWikipedia: 'rethinking-wikipedia',
  endangeredSpecies: 'bedrohte-schluesselarten-im-meer',
  freeSpace: 'freiraeume',
  noMoney: 'no-money-KUSO-GVKI',
  prevention: 'pille-kondom-oder',
  stationaryWave: 'die-stehende-welle',
  newDropbox: 'new-dropbox',
  helvetica: 'helvetica',
  immuneCompass: 'der-immunkompass',
  bachelor: 'bachelor-thesis-squared-eyes',
};

const routes: Routes = [
  { path: linkNames.home, component: StartComponent },
  { path: linkNames.aboutMe, component: AboutMeComponent },
  { path: linkNames.contact, component: ContactComponent },
  { path: linkNames.imprint, component: ImprintComponent },
  { path: linkNames.privacy, component: PrivacyComponent },
  { path: linkNames.rethinkWikipedia, component: RethinkingWikipediaComponent },
  { path: linkNames.endangeredSpecies, component: EndangeredSpeciesComponent },
  { path: linkNames.freeSpace, component: FreeSpaceComponent },
  { path: linkNames.noMoney, component: NoMoneyComponent },
  { path: linkNames.prevention, component: PreventionComponent },
  { path: linkNames.stationaryWave, component: StationaryWaveComponent },
  { path: linkNames.newDropbox, component: NewDropboxComponent },
  { path: linkNames.helvetica, component: HelveticaComponent },
  { path: linkNames.immuneCompass, component: ImmuneCompassComponent },
  { path: linkNames.bachelor, component: BachelorComponent },
  { path: '**', redirectTo: linkNames.home, component: StartComponent },
];

export const projectPages = [
  linkNames.rethinkWikipedia,
  linkNames.freeSpace,
  linkNames.noMoney,
  linkNames.endangeredSpecies,
  linkNames.prevention,
  linkNames.stationaryWave,
  linkNames.newDropbox,
  linkNames.helvetica,
  linkNames.immuneCompass,
  linkNames.bachelor,
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
