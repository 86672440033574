<ng-template #video>
  <div style="padding: 60% 0 0 0; position: relative">
    <iframe
      [src]="url"
      style="position: absolute; top: 0; left: 0; width: 100%; height: 100%"
      frameborder="0"
      allow="fullscreen; picture-in-picture"
      allowfullscreen
    ></iframe>
    <script src="https://player.vimeo.com/api/player.js"></script></div
></ng-template>
<div *ngIf="url !== ''">
  <div *ngIf="compNumber === 1">
    <ng-container *ngTemplateOutlet="video"></ng-container>
  </div>
  <div
    *ngIf="compNumber === 12"
    class="has-side-paddings has-top-bottom-spacing row w-100 justify-content-center"
  >
    <div class="col-12 col-lg-10">
      <ng-container *ngTemplateOutlet="video"></ng-container>
    </div>
  </div>
</div>
