import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-project-banner',
  templateUrl: './project-banner.component.html',
  styleUrls: ['./project-banner.component.scss'],
})
export class ProjectBannerComponent {
  @Input() imagePath = '';
  @Input() title = '';
  @Input() subtitle = '';
  @Input() link = '';
  @Input() fontColor = '#000';
}
