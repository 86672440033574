import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-c14-two-images',
  templateUrl: './c14-two-images.component.html',
  styleUrls: ['./c14-two-images.component.scss'],
})
export class C14TwoImagesComponent implements OnInit {
  @Input() imagePath1 = '';
  @Input() title1 = '';
  @Input() imagePath2 = '';
  @Input() title2 = '';
  @Input() hasSidePaddings = true;

  constructor() {}

  ngOnInit(): void {}
}
