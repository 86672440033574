<ng-template #toTopLink>
  <div class="row">
    <div class="col-12 to-top-link">
      <a [routerLink]="['.']" fragment="top" (click)="onAnchorClick()"
        ><i class="bi bi-arrow-up"></i>nach oben
      </a>
    </div>
  </div></ng-template
>

<ng-template #moreWorkLinks>
  <div class="more-work" *ngIf="ls.isProjectPage$ | async">
    <a [routerLink]="ls.formatLink(ls.previousProjectPage$ | async)"
      ><i class="bi bi-arrow-left"></i>
    </a>
    Weitere Arbeiten
    <a [routerLink]="ls.formatLink(ls.nextProjectPage$ | async)"
      ><i class="bi bi-arrow-right"></i>
    </a>
  </div>
</ng-template>

<div class="footer-container has-side-paddings">
  <div class="horizontal-line"></div>
  <div>
    <div class="to-top-link-at-top d-lg-none">
      <ng-container *ngTemplateOutlet="toTopLink"></ng-container>
    </div>
    <div class="more-work-at-top d-lg-none">
      <ng-container *ngTemplateOutlet="moreWorkLinks"></ng-container>
    </div>
  </div>

  <div class="row">
    <div class="col-6 col-lg-2">
      <ul>
        <li><a [routerLink]="ls.formatLink(ls.linkNames.home)">Home</a></li>
        <li>
          <a [routerLink]="ls.formatLink(ls.linkNames.aboutMe)">Über Mich</a>
        </li>
        <li>
          <a [routerLink]="ls.formatLink(ls.linkNames.contact)">Kontakt</a>
        </li>
      </ul>
    </div>

    <div class="col-6 col-lg-2">
      <ul>
        <li>
          <a [routerLink]="ls.formatLink(ls.linkNames.imprint)">Impressum</a>
        </li>
        <li>
          <a [routerLink]="ls.formatLink(ls.linkNames.privacy)">Datenschutz</a>
        </li>
      </ul>
    </div>

    <div class="d-none d-lg-block col-lg-2 offset-lg-1">
      <ng-container *ngTemplateOutlet="toTopLink"></ng-container>
    </div>

    <div class="d-none d-lg-block col-lg-4 text-right offset-lg-1">
      <ng-container *ngTemplateOutlet="moreWorkLinks"></ng-container>
    </div>
  </div>
</div>
