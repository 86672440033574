import { Component, ElementRef, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-text-base',
  templateUrl: './text-base.component.html',
  styleUrls: ['./text-base.component.scss'],
})
export class TextBaseComponent implements OnInit {
  @Input() nCols = 5;
  @Input() nColsShift = 0;
  @Input() headline = '';
  @Input() headlineLevel = 2;
  @Input() content: any;

  constructor() {}

  ngOnInit(): void {}
}
