import { Component, OnInit } from '@angular/core';
import { ProjectBaseComponent } from '../project-base/project-base.component';
import { ProjectBaseInterface } from '../project-base/project-base.interface';
import { Router } from '@angular/router';

@Component({
  selector: 'app-new-dropbox',
  templateUrl: './new-dropbox.component.html',
  styleUrls: ['./new-dropbox.component.scss'],
})
export class NewDropboxComponent
  extends ProjectBaseComponent
  implements OnInit, ProjectBaseInterface {
  title = 'New Dropbox';
  constructor(router: Router) {
    super(router);
  }

  ngOnInit(): void {}
}
