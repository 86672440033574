import { Component, Input, OnInit } from '@angular/core';
import { ColConfig } from '../../models/col-config.model';
import { componentColConfig } from './text-col-configs';
import { imageComponentColConfig } from '../c01-and-c10-and-c13-image/image-col-configs';

@Component({
  selector: 'app-c02-c07-text',
  templateUrl: './c02-c07-text.component.html',
  styleUrls: ['./c02-c07-text.component.scss'],
})
export class C02C07TextComponent implements OnInit {
  intCompNumber = 2;
  // @ts-ignore
  colConfig: ColConfig;
  @Input() headline = '';
  @Input() headlineLevel = 2;
  @Input() hasSidePaddings = true;
  @Input() content: any;

  @Input() set compNumber(value: number) {
    this.intCompNumber = value;
    this.colConfig = componentColConfig[this.compNumber];
  }

  get compNumber(): number {
    return this.intCompNumber;
  }
  constructor() {}

  ngOnInit(): void {}
}
