import { Component, OnInit } from '@angular/core';
import { ProjectBaseComponent } from '../project-base/project-base.component';
import { ProjectBaseInterface } from '../project-base/project-base.interface';
import { Router } from '@angular/router';

@Component({
  selector: 'app-helvetica',
  templateUrl: './helvetica.component.html',
  styleUrls: ['./helvetica.component.scss'],
})
export class HelveticaComponent
  extends ProjectBaseComponent
  implements OnInit, ProjectBaseInterface {
  title = 'Helvetica';
  constructor(router: Router) {
    super(router);
  }

  ngOnInit(): void {}
}
