<app-nav></app-nav>
<div class="has-offset-for-menu text-content">
  <app-c08-c09-image-with-text
    [imagePath]="'/assets/contact/06.jpg'"
    [headline]="'Sag Hellooo!'"
    [content]="contactContent"
  >
    <ng-template #contactContent
      >Ich freue mich dich kennenzulernen! Hinterlassen mir einfach eine
      Nachricht per Mail und ich melde mich umgehend bei dir. <br /><br /><span
        class="font-weight-bold"
        >E-Mail:
        <a href="mailto:info@anne-frauendorf-design.com"
          >info@anne-frauendorf-design.com</a
        ></span
      ></ng-template
    >
  </app-c08-c09-image-with-text>
</div>
<app-footer></app-footer>
