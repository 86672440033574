import { Component, OnInit } from '@angular/core';
import { ProjectBaseInterface } from '../project-base/project-base.interface';
import { ProjectBaseComponent } from '../project-base/project-base.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-endangered-species',
  templateUrl: './endangered-species.component.html',
  styleUrls: ['./endangered-species.component.scss'],
})
export class EndangeredSpeciesComponent
  extends ProjectBaseComponent
  implements OnInit, ProjectBaseInterface {
  title = 'Bedrohte Schlüsselarten im Meer';
  constructor(router: Router) {
    super(router);
  }

  ngOnInit(): void {}
}
