<app-project-header
  [imagePath]="'/assets/08_FREIRAEUME/Sources/01_2400x1500px.jpg'"
  [title]="title"
  [isWhite]="true"
></app-project-header>
<app-c16-spacing></app-c16-spacing>
<app-c02-c07-text
  [headline]="
    '„Über Kunst und Kultur wollen wir gerade in ländlichen Regionen neue Räume für Begegnung und Austausch schaffen.”'
  "
  [headlineLevel]="2"
  [content]="endangeredSpeciesContent"
  [compNumber]="3"
  ><ng-template #endangeredSpeciesContent
    >Mit dem neuen Förderprogramm FREIRÄUME des Ministeriums für Wissenschaft,
    Forschung und Kunst sollen Orte der Begegnung und des gemeinsamen
    Engagements im ländlichen Raum geschaffen werden. FREIRÄUME ist Teil des
    Impulsprogramms der Landesregierung für den gesellschaftlichen
    Zusammenhalt.<br /><br />
    Im Fokus bei der Entwicklung des Corporate Designs standen also die
    Schlüsselbegriffe Kunst, Kultur, Raum und Begegnung. Das hierfür entworfene
    Erscheinungsbild beinhaltet verschiedene Printmedien, sowie Webdesign und
    Social Media.</ng-template
  >
</app-c02-c07-text>
<app-c16-spacing></app-c16-spacing>
<app-c01-and-c10-and-c13-image
  [imagePath]="'/assets/08_FREIRAEUME/Sources/02_GIF.gif'"
  [title]="title"
  [compNumber]="12"
></app-c01-and-c10-and-c13-image>
<app-c16-spacing></app-c16-spacing>
<app-c02-c07-text
  [headline]="'Konzept des Erscheinungs&shy;bildes'"
  [headlineLevel]="2"
  [content]="conceptContent"
  [compNumber]="5"
  ><ng-template #conceptContent
    >Durch das Entfernen einzelner Buchstaben entstehen verschiedene Freiräume,
    die für das Erscheinungsbild ausschlaggebend sind. Diese können je nach
    Inhalt für Texte und Bilder genutzt und auch dementsprechend angepasst
    werden. Mit diesem Konzept ist es möglich, einen großen Spielraum im Design
    zu haben und jedes Produkt individuell zu gestalten. <br /><br />Team:
    Seraphina Schidlo</ng-template
  >
</app-c02-c07-text>
<app-c16-spacing></app-c16-spacing>
<app-c01-and-c10-and-c13-image
  [imagePath]="'/assets/08_FREIRAEUME/Sources/03.jpg'"
  [title]="title"
  [compNumber]="10"
></app-c01-and-c10-and-c13-image>
<app-c01-and-c10-and-c13-image
  [imagePath]="'/assets/08_FREIRAEUME/Sources/04.jpg'"
  [title]="title"
  [compNumber]="10"
></app-c01-and-c10-and-c13-image>
<app-c01-and-c10-and-c13-image
  [imagePath]="'/assets/08_FREIRAEUME/Sources/05.jpg'"
  [title]="title"
  [compNumber]="10"
></app-c01-and-c10-and-c13-image>
<app-c16-spacing></app-c16-spacing>
<app-video [key]="'510273361'" [compNumber]="12"></app-video>
<app-c16-spacing></app-c16-spacing>
<app-c01-and-c10-and-c13-image
  [imagePath]="'/assets/08_FREIRAEUME/Sources/07.png'"
  [title]="title"
  [compNumber]="1"
></app-c01-and-c10-and-c13-image>
<app-c16-spacing></app-c16-spacing>
<app-c16-spacing></app-c16-spacing>
<app-c11-slider [images]="images"> </app-c11-slider>
<app-footer></app-footer>
