<app-project-header
  [imagePath]="'/assets/04_Helvetica/Sources/01_2400x1500px.jpg'"
  [title]="title"
  [isWhite]="true"
></app-project-header>
<app-c16-spacing></app-c16-spacing>
<app-c01-and-c10-and-c13-image
  [imagePath]="'/assets/04_Helvetica/Sources/02_Neu.jpg'"
  [title]="title"
  [compNumber]="1"
></app-c01-and-c10-and-c13-image>
<app-c16-spacing></app-c16-spacing>

<app-c08-c09-image-with-text
  [headline]="'Die Anatomie der Helvetica'"
  [content]="content1"
  [textFirst]="false"
  [title]="title"
  [imagePath]="'/assets/04_Helvetica/Sources/03.jpg'"
  ><ng-template #content1
    >Bei einer genauen Betrachtung und Analyse fallen einem viele Merkmale und
    Besonderheiten auf, die den Charakter und die DNA einer Schrift
    auszeichnen.<br />
    Unter dem Dachthema „Die Anatomie der Zeichen“ sollen die visuellen Codes
    der Helvetica sichtbar gemacht werden. Als Medium wurde hierfür ein Magazin
    gestaltet.</ng-template
  ></app-c08-c09-image-with-text
>
<app-c16-spacing></app-c16-spacing>
<app-c16-spacing></app-c16-spacing>
<app-c14-two-images
  [hasSidePaddings]="true"
  [imagePath1]="'/assets/04_Helvetica/Sources/04.jpg'"
  [title1]="title"
  [imagePath2]="'/assets/04_Helvetica/Sources/05.jpg'"
  [title2]="title"
></app-c14-two-images>
<app-c01-and-c10-and-c13-image
  [imagePath]="'/assets/04_Helvetica/Sources/06.jpg'"
  [title]="title"
  [compNumber]="10"
></app-c01-and-c10-and-c13-image>

<app-c16-spacing></app-c16-spacing>
<app-c16-spacing></app-c16-spacing>
<app-c01-and-c10-and-c13-image
  [imagePath]="'/assets/04_Helvetica/Sources/07.jpg'"
  [title]="title"
  [compNumber]="1"
></app-c01-and-c10-and-c13-image>
<app-c16-spacing></app-c16-spacing>
<app-c02-c07-text
  [headline]="'Inhalt des Magazins'"
  [headlineLevel]="2"
  [content]="content2"
  [compNumber]="5"
  ><ng-template #content2
    >Neben den Typografen Max Miedinger und Jürgen Hoffmann beinhaltet das
    Magazin Originalnotizen und Skizzen aus dem Protokollheft der beiden
    Typografen. Diese zeigen die Entwicklung der Schriftschnitte, der
    Makrotypografie und der Sonderzeichen, die jeweils in einem eigenen Kapitel
    ausführlich behandelt werden.</ng-template
  >
</app-c02-c07-text>
<app-c16-spacing></app-c16-spacing>

<app-c01-and-c10-and-c13-image
  [imagePath]="'/assets/04_Helvetica/Sources/08.jpg'"
  [title]="title"
  [compNumber]="13"
></app-c01-and-c10-and-c13-image>
<app-c01-and-c10-and-c13-image
  [imagePath]="'/assets/04_Helvetica/Sources/09.jpg'"
  [title]="title"
  [compNumber]="15"
></app-c01-and-c10-and-c13-image>

<app-c14-two-images
  [hasSidePaddings]="true"
  [imagePath1]="'/assets/04_Helvetica/Sources/10.jpg'"
  [title1]="title"
  [imagePath2]="'/assets/04_Helvetica/Sources/11.jpg'"
  [title2]="title"
></app-c14-two-images>

<app-footer></app-footer>
