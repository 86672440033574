<div
  [ngClass]="{ 'has-side-paddings': hasSidePaddings }"
  class="has-top-bottom-spacing"
>
  <!-- =< small: Smartphone -->
  <div class="d-md-none">
    <!--    small and smaller-->
    <app-text-base
      *ngIf="content"
      [nCols]="colConfig.smartphone.nCols"
      [nColsShift]="colConfig.smartphone.colsShift"
      [headline]="headline"
      [headlineLevel]="headlineLevel"
      [content]="content"
    >
    </app-text-base>
  </div>

  <!-- medium: Tablet Portrait -->
  <div class="d-none d-md-block d-lg-none">
    <!--    medium-->
    <app-text-base
      *ngIf="content"
      [nCols]="colConfig.tabletPortrait.nCols"
      [nColsShift]="colConfig.tabletPortrait.colsShift"
      [headline]="headline"
      [headlineLevel]="headlineLevel"
      [content]="content"
    >
    </app-text-base>
  </div>

  <!-- large: Tablet Landscape -->
  <div class="d-none d-lg-block d-xl-none">
    <!--    large-->
    <app-text-base
      *ngIf="content"
      [nCols]="colConfig.tabletLandscape.nCols"
      [nColsShift]="colConfig.tabletLandscape.colsShift"
      [headline]="headline"
      [headlineLevel]="headlineLevel"
      [content]="content"
    >
    </app-text-base>
  </div>

  <!-- >= large: Desktop -->
  <div class="d-none d-xl-block">
    <!--    larger than large-->
    <app-text-base
      *ngIf="content"
      [nCols]="colConfig.desktop.nCols"
      [nColsShift]="colConfig.desktop.colsShift"
      [headline]="headline"
      [headlineLevel]="headlineLevel"
      [content]="content"
    >
    </app-text-base>
  </div>
</div>
