<div [ngClass]="{ 'has-side-paddings': hasSidePaddings }">
  <div class="row justify-content-center">
    <div class="col-12 col-md-10 col-lg-6 has-top-bottom-spacing">
      <app-c01-and-c10-and-c13-image
        [imagePath]="imagePath1"
        [title]="title1"
        [hasSidePaddings]="false"
      ></app-c01-and-c10-and-c13-image>
    </div>
    <div class="col-12 col-md-10 col-lg-6 has-top-bottom-spacing">
      <app-c01-and-c10-and-c13-image
        [imagePath]="imagePath2"
        [title]="title2"
        [hasSidePaddings]="false"
      ></app-c01-and-c10-and-c13-image>
    </div>
  </div>
</div>
