<div class="project-banner-container" [routerLink]="link">
  <div class="title-container">
    <span class="subtitle banner-title">{{ subtitle }}</span>
    <span class="title banner-title">{{ title }}</span>
  </div>

  <div class="bottom-title-container banner-title">
    <span class="bottom-title" [ngStyle]="{ color: fontColor }">{{
      title
    }}</span>
  </div>

  <app-custom-img-tag
    [imagePath]="imagePath"
    [title]="title"
  ></app-custom-img-tag>
</div>
