import { Component, OnInit } from '@angular/core';
import { ProjectBaseComponent } from '../project-base/project-base.component';
import { ProjectBaseInterface } from '../project-base/project-base.interface';
import { Router } from '@angular/router';

@Component({
  selector: 'app-stationary-wave',
  templateUrl: './stationary-wave.component.html',
  styleUrls: ['./stationary-wave.component.scss'],
})
export class StationaryWaveComponent
  extends ProjectBaseComponent
  implements OnInit, ProjectBaseInterface {
  title = 'Die stehende Welle';

  images = [
    '/assets/05_Die-stehende-Welle/Sources/02_Slider.jpg',
    '/assets/05_Die-stehende-Welle/Sources/03_Slider.jpg',
    '/assets/05_Die-stehende-Welle/Sources/04_Slider.jpg',
    '/assets/05_Die-stehende-Welle/Sources/05_Slider.jpg',
    '/assets/05_Die-stehende-Welle/Sources/06_Slider.jpg',
    '/assets/05_Die-stehende-Welle/Sources/07_Slider.jpg',
  ];

  constructor(router: Router) {
    super(router);
  }

  ngOnInit(): void {}
}
