<app-project-header
  [imagePath]="'/assets/05_Die-stehende-Welle/Sources/01_2400x1500px.jpg'"
  [title]="title"
></app-project-header>
<app-c16-spacing></app-c16-spacing>
<app-c02-c07-text
  [headline]="'Die stehende Welle – Akustische Phänomene'"
  [headlineLevel]="1"
  [content]="content1"
  [compNumber]="7"
  ><ng-template #content1
    >Wie kann man das unsichtbare sichtbar machen? Unter dem Dachthema
    „akustische Phänomene“ erarbeiteten wir in Kooperation mit der „Experimenta“
    in Heilbronn ein interaktives Konzept aus, welches das Phänomen der
    stehenden Welle anhand tieffrequenter Töne/Schallwellen für Besucher*innen
    erfahr- und erlebbar werden lässt. Die Ausstellung soll im besten Fall das
    akustische Phänomen ganzheitlich erklären, um später einen Platz in der
    Lern-und Erlebniswelt der „Experimenta“ zu finden.</ng-template
  >
</app-c02-c07-text>
<app-c16-spacing></app-c16-spacing>
<app-c11-slider [images]="images"> </app-c11-slider>
<app-c16-spacing></app-c16-spacing>

<app-c02-c07-text
  [headline]="'Bodenbeschriftung'"
  [headlineLevel]="2"
  [content]="content2"
  [compNumber]="4"
  ><ng-template #content2
    >Um Besucher*innen wortwörtlich Schritt für Schritt durch das Exponat zu
    führen, wurden Textblöcke auf den Boden geklebt, deren Positionen sich an
    den leisen und lauten Stellen der Welle orientieren. Durch das Innehalten
    wird einem das Phänomen akustisch erfahrbar gemacht und zusätzlich erklärt.
    Des Weiteren wurden die Texte auf dem Boden platziert, damit die
    Besucher*innen die Köpfe gerade halten. Würde man den Kopf zum Lesen
    Richtung Wand drehen, kann man das Phänomen nicht mehr hören.</ng-template
  >
</app-c02-c07-text>
<app-c16-spacing></app-c16-spacing>
<app-c14-two-images
  [hasSidePaddings]="true"
  [imagePath1]="'/assets/05_Die-stehende-Welle/Sources/08.jpg'"
  [title1]="title"
  [imagePath2]="'/assets/05_Die-stehende-Welle/Sources/09.jpg'"
  [title2]="title"
></app-c14-two-images>
<app-c01-and-c10-and-c13-image
  [imagePath]="'/assets/05_Die-stehende-Welle/Sources/10.jpg'"
  [title]="title"
  [compNumber]="13"
></app-c01-and-c10-and-c13-image>
<app-c16-spacing></app-c16-spacing>
<app-c08-c09-image-with-text
  [headline]="'Informationsebenen'"
  [content]="content3"
  [textFirst]="true"
  [title]="title"
  [imagePath]="'/assets/05_Die-stehende-Welle/Sources/11.jpg'"
  ><ng-template #content3
    >Bewegt man sich nun weiter, kommt man zur interaktiven Simulation der drei
    Schallwellen. Die vom Subwoofer entsendete, die von der Wand reflektierte
    und die resultierende stehende Welle werden auf einem Tablet in Slow Motion
    visualisiert.<br /><br />
    Die letzte Station des Rundgangs führt die Besucher*innen zu den drei
    Plakaten, die zuerst das Phänomen nochmals ausführlich erläutern. Das Zweite
    erklärt die Entstehung von stehenden Wellen und geht dabei auf die einzelnen
    Bedingungen und physikalischen Größen ein. Zuletzt bekommen die
    Besucher*innen auf Plakat 3 noch einen Überblick zu realitätsbezogenen
    Anwendungsbeispielen oder Vorkommen von stehenden Wellen in der Akustik.
    Hiermit schließt das Exponat nun ab.<br /><br />Teampartner: Aileen Gedrat
    und Simon Widmann</ng-template
  ></app-c08-c09-image-with-text
>
<app-c14-two-images
  [hasSidePaddings]="true"
  [imagePath1]="'/assets/05_Die-stehende-Welle/Sources/12.jpg'"
  [title1]="title"
  [imagePath2]="'/assets/05_Die-stehende-Welle/Sources/13.jpg'"
  [title2]="title"
></app-c14-two-images>
<app-c16-spacing></app-c16-spacing>
<app-video [key]="'512266969'" [compNumber]="12"></app-video>
<app-footer></app-footer>
