import { Component, Input, OnInit } from '@angular/core';
import { ColConfig } from '../../models/col-config.model';
import { componentColConfig } from '../c02-c07-text/text-col-configs';
import { imageComponentColConfig } from './image-col-configs';

@Component({
  selector: 'app-c01-and-c10-and-c13-image',
  templateUrl: './c01-and-c10-and-c13-image.component.html',
  styleUrls: ['./c01-and-c10-and-c13-image.component.scss'],
})
export class C01AndC10AndC13ImageComponent implements OnInit {
  intCompNumber = 1;
  // @ts-ignore
  colConfig: ColConfig;
  @Input() imagePath = '';
  @Input() title = '';
  @Input() hasSidePaddings = true;
  @Input() hasTopBottomSpacing = true;

  @Input() set compNumber(value: number) {
    this.intCompNumber = value;
    this.colConfig = imageComponentColConfig[this.compNumber];
  }

  get compNumber(): number {
    return this.intCompNumber;
  }

  constructor() {}

  ngOnInit(): void {}
}
