<app-project-header
  [imagePath]="'/assets/01_Rethinking-Wikipedia/Sources/01_2400x1500px.jpg'"
  [title]="title"
></app-project-header>
<app-c16-spacing></app-c16-spacing>

<app-c02-c07-text
  [compNumber]="5"
  [headline]="'Rethinking Wikipedia'"
  [headlineLevel]="1"
  [content]="rethinkingWikipediaContent"
  ><ng-template #rethinkingWikipediaContent
    >Wikipedia ist ein einzigartiges und bewundernswertes Projekt, welches das
    Wissen für jeden digital zugänglich macht. Jedoch wurde das Layout seit der
    Veröffentlichung kaum verändert, was viele Probleme mit sich bringt. <br />
    <br />
    Ziel ist es, Lösungen für diese Problemen zu finden und spezifische Inhalte
    ideal zu strukturieren und formatieren. Zusätzlich musste ein Designsystem
    entwickelt werden, das modular und flexibel einsetzbar ist aber trotzdem
    konsistent und wiedererkennbar bleibt.</ng-template
  ></app-c02-c07-text
>
<app-c16-spacing></app-c16-spacing>
<app-c01-and-c10-and-c13-image
  [imagePath]="'/assets/01_Rethinking-Wikipedia/Sources/02.jpg'"
  [title]="title"
  [compNumber]="13"
></app-c01-and-c10-and-c13-image>
<app-c16-spacing></app-c16-spacing>
<app-c02-c07-text
  [headline]="'Darstellung von Daten'"
  [compNumber]="7"
  [content]="dataVizContent"
  ><ng-template #dataVizContent
    >Überfüllte Tabellen mit zu viel Text werden in den meisten Fällen direkt
    übergangen. Aus diesem Grund werden mit Hilfe von Zahlen und Daten visuell
    ansprechende Informationsgrafiken erstellt. Diese sind zudem interaktiv,
    sodass User*innen die Möglichkeit haben, mehr Informationen zu
    bekommen.</ng-template
  ></app-c02-c07-text
>
<app-c16-spacing></app-c16-spacing>
<app-c01-and-c10-and-c13-image
  [imagePath]="'/assets/01_Rethinking-Wikipedia/Sources/03.jpg'"
  [title]="title"
  [compNumber]="1"
></app-c01-and-c10-and-c13-image>
<app-c16-spacing></app-c16-spacing>
<app-c08-c09-image-with-text
  [textFirst]="true"
  [title]="title"
  [imagePath]="'/assets/01_Rethinking-Wikipedia/Sources/04..png'"
  [headline]="'Styleguide Farbe und Icons'"
  [content]="styleGuideContent"
>
  <ng-template #styleGuideContent
    >Ein Teil des Styleguides beinhaltet die Farben, die für das Redesign
    eingesetzt werden. Neben Weiß und ein einem dunklen Grauton für den
    Fließtext, werden Highlightfarben in verschiedenen Türkistönen verwendet.
    Die Farbe lehnt sich an das Blau von Wikipedia an, wirkt aber moderner und
    lebendiger. <br /><br />Teampartner: Seraphina Schidlo</ng-template
  ></app-c08-c09-image-with-text
>
<app-c16-spacing></app-c16-spacing>
<app-video [key]="'512486349'" [compNumber]="1"></app-video>
<app-footer></app-footer>
