import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-c16-spacing',
  templateUrl: './c16-spacing.component.html',
  styleUrls: ['./c16-spacing.component.scss'],
})
export class C16SpacingComponent implements OnInit {
  @Input() height = 120;

  constructor() {}

  ngOnInit(): void {}
}
