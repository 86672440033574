import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-custom-img-tag',
  templateUrl: './custom-img-tag.component.html',
  styleUrls: ['./custom-img-tag.component.scss'],
})
export class CustomImgTagComponent implements OnInit {
  @Input() imagePath = '';
  @Input() title = '';

  showMyElement = false;

  constructor() {}

  ngOnInit(): void {}
}
