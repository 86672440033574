import { Component, Input, OnInit } from '@angular/core';
import { LinkService } from '../../services/link.service';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
})
export class NavComponent implements OnInit {
  @Input() isWhite = false;

  open = false;
  constructor(public ls: LinkService) {}

  ngOnInit(): void {}

  clickEvent(): void {
    this.open = !this.open;
  }
}
