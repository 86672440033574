<app-project-header
  [imagePath]="'/assets/06_Pille-Kondom-oder/Sources/01_2400x1500px.jpg'"
  [title]="title"
></app-project-header>
<app-c16-spacing></app-c16-spacing>
<app-c08-c09-image-with-text
  [headline]="'Pille, Kondom oder'"
  [content]="content1"
  [textFirst]="true"
  [title]="title"
  [imagePath]="'/assets/06_Pille-Kondom-oder/Sources/02.jpg'"
  ><ng-template #content1
    >Verhütung ist bis heute ein umstrittenes Thema, über das in der
    Öffentlichkeit kaum gesprochen wird. Die Broschüre „Pille, Kondom oder“
    bietet eine Plattform für die am häufigsten verwendeten Verhütungsmittel und
    stellt eine direkte Übersicht dar. Mithilfe informativer Texte, klarer
    Piktogramme und selbst fotografierter Produktbilder wird für den Leser eine
    ausreichende Aufklärung ermöglicht.
  </ng-template>
</app-c08-c09-image-with-text>
<app-c16-spacing></app-c16-spacing>
<app-c01-and-c10-and-c13-image
  [imagePath]="'/assets/06_Pille-Kondom-oder/Sources/03.jpg'"
  [title]="title"
  [compNumber]="10"
></app-c01-and-c10-and-c13-image>
<app-c01-and-c10-and-c13-image
  [imagePath]="'/assets/06_Pille-Kondom-oder/Sources/04.jpg'"
  [title]="title"
  [compNumber]="10"
></app-c01-and-c10-and-c13-image>
<app-c16-spacing></app-c16-spacing>
<app-c01-and-c10-and-c13-image
  [imagePath]="'/assets/06_Pille-Kondom-oder/Sources/05.png'"
  [title]="title"
  [compNumber]="1"
></app-c01-and-c10-and-c13-image>
<app-c16-spacing></app-c16-spacing>
<app-c02-c07-text
  [headline]="'Farbe, Illustrationen und Icons'"
  [headlineLevel]="2"
  [content]="content2"
  [compNumber]="4"
  ><ng-template #content2
    >Für die Anwendungsbeispiele der einzelnen Verhütungsmethoden, haben sich
    Grafiken am besten geeignet. Um das Objekt hervorzuheben, wurde bewusst mit
    zwei Farben gearbeitet. Das Prinzip zieht sich durch die ganze Broschüre,
    sowie auch die Icons. Diese stehen für den Pearl Index, für die
    Wirkungsweise (hormonell/hormonfrei), wann die jeweiligen Verhütungsmitteln
    eingenommen werden müssen und was jedes kostet.<br /><br />Teampartner: Aise
    Erzeybek</ng-template
  >
</app-c02-c07-text>
<app-c01-and-c10-and-c13-image
  [imagePath]="'/assets/06_Pille-Kondom-oder/Sources/06.png'"
  [title]="title"
  [compNumber]="1"
></app-c01-and-c10-and-c13-image>
<app-c16-spacing></app-c16-spacing>
<app-c16-spacing></app-c16-spacing>
<app-c01-and-c10-and-c13-image
  [imagePath]="'/assets/06_Pille-Kondom-oder/Sources/07.jpg'"
  [title]="title"
  [compNumber]="13"
></app-c01-and-c10-and-c13-image>
<app-c01-and-c10-and-c13-image
  [imagePath]="'/assets/06_Pille-Kondom-oder/Sources/08.jpg'"
  [title]="title"
  [compNumber]="10"
></app-c01-and-c10-and-c13-image>
<app-c01-and-c10-and-c13-image
  [imagePath]="'/assets/06_Pille-Kondom-oder/Sources/09.jpg'"
  [title]="title"
  [compNumber]="10"
></app-c01-and-c10-and-c13-image>
<app-c01-and-c10-and-c13-image
  [imagePath]="'/assets/06_Pille-Kondom-oder/Sources/10.jpg'"
  [title]="title"
  [compNumber]="10"
></app-c01-and-c10-and-c13-image>
<app-footer></app-footer>
