import { Component, OnInit } from '@angular/core';
import { ProjectBaseComponent } from '../project-base/project-base.component';
import { ProjectBaseInterface } from '../project-base/project-base.interface';
import { Router } from '@angular/router';

@Component({
  selector: 'app-no-money',
  templateUrl: './no-money.component.html',
  styleUrls: ['./no-money.component.scss'],
})
export class NoMoneyComponent
  extends ProjectBaseComponent
  implements OnInit, ProjectBaseInterface {
  title = 'No Money – KUSO GVKI';

  constructor(router: Router) {
    super(router);
  }

  ngOnInit(): void {}
}
