import { Component, OnInit } from '@angular/core';
import { ProjectBaseComponent } from '../project-base/project-base.component';
import { ProjectBaseInterface } from '../project-base/project-base.interface';
import { Router } from '@angular/router';

@Component({
  selector: 'app-free-space',
  templateUrl: './free-space.component.html',
  styleUrls: ['./free-space.component.scss'],
})
export class FreeSpaceComponent
  extends ProjectBaseComponent
  implements OnInit, ProjectBaseInterface {
  title = 'FREIRAEUME';

  images = [
    '/assets/08_FREIRAEUME/Sources/09_Slider.jpg',
    '/assets/08_FREIRAEUME/Sources/10_Slider.jpg',
    '/assets/08_FREIRAEUME/Sources/11_Slider.jpg',
    '/assets/08_FREIRAEUME/Sources/12_Slider.jpg',
  ];

  constructor(router: Router) {
    super(router);
  }

  ngOnInit(): void {}
}
