import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-c11-slider',
  templateUrl: './c11-slider.component.html',
  styleUrls: ['./c11-slider.component.scss'],
})
export class C11SliderComponent implements OnInit {
  @Input() images = [''];
  constructor() {}

  ngOnInit(): void {}
}
