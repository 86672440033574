import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss'],
})
export class VideoComponent implements OnInit {
  url: SafeResourceUrl = '';
  @Input() set key(val: string) {
    this.url = this.url = this.sanitizer.bypassSecurityTrustResourceUrl(
      'https://player.vimeo.com/video/' +
        val +
        '?autoplay=0&loop=1&title=0&byline=0&portrait=0&background=1'
    );
  }

  @Input() compNumber = 12;

  constructor(public sanitizer: DomSanitizer) {}

  ngOnInit(): void {}
}
