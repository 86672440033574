import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-project-header',
  templateUrl: './project-header.component.html',
  styleUrls: ['./project-header.component.scss'],
})
export class ProjectHeaderComponent implements OnInit {
  @Input() imagePath = '';
  @Input() title = '';
  @Input() isWhite = false;

  constructor() {}

  ngOnInit(): void {}
}
