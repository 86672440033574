import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { StartComponent } from './pages/start/start.component';
import { LogoComponent } from './components/logo/logo.component';
import { NavComponent } from './components/nav/nav.component';
import { ProjectBannerComponent } from './components/project-banner/project-banner.component';
import { FooterComponent } from './components/footer/footer.component';
import { ProjectHeaderComponent } from './components/project-header/project-header.component';
import { RethinkingWikipediaComponent } from './pages/rethinking-wikipedia/rethinking-wikipedia.component';
import { ProjectBaseComponent } from './pages/project-base/project-base.component';
import { C02C07TextComponent } from './components/c02-c07-text/c02-c07-text.component';
import { C16SpacingComponent } from './components/c16-spacing/c16-spacing.component';
import { C08C09ImageWithTextComponent } from './components/c08-c09-image-with-text/c08-c09-image-with-text.component';
import { C01AndC10AndC13ImageComponent } from './components/c01-and-c10-and-c13-image/c01-and-c10-and-c13-image.component';
import { EndangeredSpeciesComponent } from './pages/endangered-species/endangered-species.component';
import { C14TwoImagesComponent } from './components/c14-two-images/c14-two-images.component';
import { TextBaseComponent } from './components/text-base/text-base.component';
import { ImageBaseComponent } from './components/image-base/image-base.component';
import { FreeSpaceComponent } from './pages/free-space/free-space.component';
import { NoMoneyComponent } from './pages/no-money/no-money.component';
import { StationaryWaveComponent } from './pages/stationary-wave/stationary-wave.component';
import { NewDropboxComponent } from './pages/new-dropbox/new-dropbox.component';
import { HelveticaComponent } from './pages/helvetica/helvetica.component';
import { ImmuneCompassComponent } from './pages/immune-compass/immune-compass.component';
import { PreventionComponent } from './pages/prevention/prevention.component';
import { VideoComponent } from './components/video/video.component';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { C11SliderComponent } from './components/c11-slider/c11-slider.component';
import { ImprintComponent } from './pages/imprint/imprint.component';
import { AboutMeComponent } from './pages/about-me/about-me.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';
import { ContactComponent } from './pages/contact/contact.component';
import {
  NgcCookieConsentModule,
  NgcCookieConsentConfig,
} from 'ngx-cookieconsent';
import { DeferLoadModule } from '@trademe/ng-defer-load';
import { CustomImgTagComponent } from './components/custom-img-tag/custom-img-tag.component';
import { BachelorComponent } from './pages/bachelor/bachelor.component';

const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: 'anne-frauendorf-design.com',
  },
  position: 'bottom',
  theme: 'classic',
  palette: {
    popup: {
      background: '#000000',
      text: '#ffffff',
      link: '#ffffff',
    },
    button: {
      background: '#fff',
      text: '#000000',
      border: 'transparent',
    },
  },
  type: 'info',
  content: {
    message:
      'Diese Website verwendet Cookies, um Ihnen das beste Erlebnis auf unserer Website zu bieten.',
    dismiss: 'Okay!',
    deny: 'Refuse cookies',
    link: 'Mehr Infos zu Cookies',
    href: '/datenschutz',
    policy: 'Cookie Policy',
  },
};

@NgModule({
  declarations: [
    AppComponent,
    StartComponent,
    LogoComponent,
    NavComponent,
    ProjectBannerComponent,
    FooterComponent,
    ProjectHeaderComponent,
    RethinkingWikipediaComponent,
    ProjectBaseComponent,
    C02C07TextComponent,
    C16SpacingComponent,
    C08C09ImageWithTextComponent,
    C01AndC10AndC13ImageComponent,
    EndangeredSpeciesComponent,
    C14TwoImagesComponent,
    TextBaseComponent,
    ImageBaseComponent,
    FreeSpaceComponent,
    NoMoneyComponent,
    StationaryWaveComponent,
    NewDropboxComponent,
    HelveticaComponent,
    ImmuneCompassComponent,
    PreventionComponent,
    VideoComponent,
    C11SliderComponent,
    ImprintComponent,
    AboutMeComponent,
    PrivacyComponent,
    ContactComponent,
    CustomImgTagComponent,
    BachelorComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    IvyCarouselModule,
    NgcCookieConsentModule.forRoot(cookieConfig),
    DeferLoadModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
