<div class="row">
  <div *ngIf="nColsShift > 0" [class]="'col-' + nColsShift"></div>
  <div [class]="'col-' + nCols">
    <ng-container *ngIf="headline !== ''">
      <h1 *ngIf="headlineLevel === 1" [innerHTML]="headline"></h1>
      <h2 *ngIf="headlineLevel === 2" [innerHTML]="headline"></h2>
    </ng-container>
    <div class="text-component-content" *ngIf="content">
      <ng-container><div *ngTemplateOutlet="content"></div></ng-container>
    </div>
  </div>
</div>
