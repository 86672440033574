import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-image-base',
  templateUrl: './image-base.component.html',
  styleUrls: ['./image-base.component.scss'],
})
export class ImageBaseComponent implements OnInit {
  @Input() nCols = 12;
  @Input() nColsShift = 0;
  @Input() imagePath = '';
  @Input() title = '';

  constructor() {}

  ngOnInit(): void {}
}
