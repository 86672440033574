<app-nav></app-nav>
<div
  class="project-banners row row-cols-1 row-cols-lg-2 no-gutters has-offset-for-menu"
>
  <div class="col">
    <app-project-banner
      [title]="'Rethinking Wikipedia'"
      [subtitle]="'Web Design | Redesign'"
      [imagePath]="'/assets/01_Rethinking-Wikipedia/Sources/teaser.jpg'"
      [link]="ls.formatLink(ls.linkNames.rethinkWikipedia)"
    ></app-project-banner>
  </div>
  <div class="col">
    <app-project-banner
      [title]="'FREIRÄUME'"
      [subtitle]="'Corporate Identity | Typografie'"
      [imagePath]="'/assets/08_FREIRAEUME/Sources/teaser.jpg'"
      [link]="ls.formatLink(ls.linkNames.freeSpace)"
      [fontColor]="'#FFF'"
    ></app-project-banner>
  </div>
  <div class="col">
    <app-project-banner
      [title]="'No Money - KUSO GVKI'"
      [subtitle]="'Audiovisuelle Gestaltung | Animation'"
      [imagePath]="'/assets/03_Audiovisuelle-Gestaltung/Sources/teaser.jpg'"
      [link]="ls.formatLink(ls.linkNames.noMoney)"
      [fontColor]="'#FFF'"
    ></app-project-banner>
  </div>
  <div class="col">
    <app-project-banner
      [title]="'Bedrohte Schlüsselarten im Meer'"
      [subtitle]="'Informationsgestaltung | Magazin Design | Illustration'"
      [imagePath]="
        '/assets/02_Bedrohte-Schluesselarten-im-Meer/Sources/teaser.jpg'
      "
      [link]="ls.formatLink(ls.linkNames.endangeredSpecies)"
      [fontColor]="'#FFF'"
    ></app-project-banner>
  </div>
  <div class="col">
    <app-project-banner
      [title]="'Pille, Kondom oder ...'"
      [subtitle]="'Magazindesign | Fotografie'"
      [imagePath]="'/assets/06_Pille-Kondom-oder/Sources/teaser.jpg'"
      [link]="ls.formatLink(ls.linkNames.prevention)"
      [fontColor]="'#FFF'"
    ></app-project-banner>
  </div>
  <div class="col">
    <app-project-banner
      [title]="'Die stehende Welle'"
      [subtitle]="'Ausstellungsgestaltung'"
      [imagePath]="'/assets/05_Die-stehende-Welle/Sources/teaser.jpg'"
      [link]="ls.formatLink(ls.linkNames.stationaryWave)"
      [fontColor]="'#000'"
    ></app-project-banner>
  </div>
  <div class="col">
    <app-project-banner
      [title]="'New Dropbox'"
      [subtitle]="'App Design | Redesign'"
      [imagePath]="'/assets/07_Dropbox-Redesign/Sources/teaser.jpg'"
      [link]="ls.formatLink(ls.linkNames.newDropbox)"
      [fontColor]="'#000'"
    ></app-project-banner>
  </div>
  <div class="col">
    <app-project-banner
      [title]="'Helvetica'"
      [subtitle]="'Magazindesign | Typografie'"
      [imagePath]="'/assets/04_Helvetica/Sources/teaser.jpg'"
      [link]="ls.formatLink(ls.linkNames.helvetica)"
      [fontColor]="'#FFF'"
    ></app-project-banner>
  </div>
  <div class="col">
    <app-project-banner
      [title]="'Der Immunkompass'"
      [subtitle]="'Informationsgestaltung | Animation | Webgestaltung'"
      [imagePath]="'/assets/09_Der-Immunkompass/Sources/teaser.jpg'"
      [link]="ls.formatLink(ls.linkNames.immuneCompass)"
    ></app-project-banner>
  </div>
  <div class="col">
    <app-project-banner
      [title]="'Bachelorthesis - Squared Eyed'"
      [subtitle]="'Ausstellungsgestaltung | Informationsgestaltung'"
      [imagePath]="'/assets/10_bachelor/Sources/teaser.jpg'"
      [link]="ls.formatLink(ls.linkNames.bachelor)"
    ></app-project-banner>
  </div>
</div>

<app-footer></app-footer>
