<app-project-header
  [imagePath]="
    '/assets/02_Bedrohte-Schluesselarten-im-Meer/Sources/01_2400x1500px.jpg'
  "
  [title]="title"
></app-project-header>
<app-c16-spacing></app-c16-spacing>
<app-c02-c07-text
  [headline]="'Bedrohte Schlüsselarten im Meer'"
  [headlineLevel]="1"
  [content]="endangeredSpeciesContent"
  [compNumber]="7"
  ><ng-template #endangeredSpeciesContent
    >Plastik in den Ozeanen ist schon lange ein Thema, jedoch vergisst man
    schnell, dass das Meer und dessen Bewohner noch weitaus schlimmere Probleme
    ertragen müssen. Ohne die Schlüsselarten Fische, Haie, Wale, Plankton und
    Korallen würde das gesamte Ökosystem im Meer zusammen brechen. Die Folgen
    würden die Artenvielfalt aber auch uns Menschen schwer treffen.<br /><br />
    Das Projekt „Bedrohte Schlüsselarten im Meer“ klärt über die aktuelle Lage
    dieser Schlüsselarten auf und veranschaulicht, wie sich die Situation durch
    den Klimawandel und durch Menschenhand verändert hat.</ng-template
  >
</app-c02-c07-text>
<app-c16-spacing></app-c16-spacing>
<app-c01-and-c10-and-c13-image
  [imagePath]="'/assets/02_Bedrohte-Schluesselarten-im-Meer/Sources/02.jpg'"
  [title]="title"
  [compNumber]="1"
></app-c01-and-c10-and-c13-image>
<app-c16-spacing></app-c16-spacing>
<app-c01-and-c10-and-c13-image
  [imagePath]="'/assets/02_Bedrohte-Schluesselarten-im-Meer/Sources/03.jpg'"
  [title]="title"
  [compNumber]="10"
></app-c01-and-c10-and-c13-image>
<app-c01-and-c10-and-c13-image
  [imagePath]="'/assets/02_Bedrohte-Schluesselarten-im-Meer/Sources/04.jpg'"
  [title]="title"
  [compNumber]="10"
></app-c01-and-c10-and-c13-image>
<app-c14-two-images
  [imagePath1]="'/assets/02_Bedrohte-Schluesselarten-im-Meer/Sources/05.jpg'"
  [title1]="title"
  [imagePath2]="'/assets/02_Bedrohte-Schluesselarten-im-Meer/Sources/06.jpg'"
  [title2]="title"
></app-c14-two-images>
<app-c16-spacing></app-c16-spacing>
<app-c02-c07-text
  [headline]="'Die Umsetzung'"
  [compNumber]="5"
  [content]="executionContent"
  ><ng-template #executionContent
    >Um ausführlich über die Bedeutung der Artenvielfalt und der Schlüsselarten
    im Meer zu informieren, gibt es für jede eine eigene Broschüre. Der
    inhaltliche Aufbau ist dabei immer gleich geblieben. Zuerst wurde die Frage
    beantwortet, warum diese Tierart zu den Schlüsselarten gehört und wodurch
    diese gefährdet wird. Neben den Texten werden Illustrationen eingesetzt, die
    die Visualisierung unterstützen. <br /><br />
    Teampartner: Aileen Gedrat und Lisa Amann</ng-template
  >
</app-c02-c07-text>
<app-c01-and-c10-and-c13-image
  [imagePath]="'/assets/02_Bedrohte-Schluesselarten-im-Meer/Sources/07.jpg'"
  [title]="title"
  [compNumber]="13"
></app-c01-and-c10-and-c13-image>
<app-c14-two-images
  [imagePath1]="'/assets/02_Bedrohte-Schluesselarten-im-Meer/Sources/08.jpg'"
  [title1]="title"
  [imagePath2]="'/assets/02_Bedrohte-Schluesselarten-im-Meer/Sources/09.jpg'"
  [title2]="title"
></app-c14-two-images>
<app-footer></app-footer>
