import { Component, OnInit } from '@angular/core';
import { LinkService } from '../../services/link.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  constructor(
    public ls: LinkService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {}

  onAnchorClick(): void {
    this.route.fragment.subscribe((f) => {
      const element = document.querySelector('#' + f);
      // @ts-ignore
      element?.scrollIntoView(element);
    });
  }
}
