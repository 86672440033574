<app-project-header
  [imagePath]="'/assets/09_Der-Immunkompass/Sources/01_2400x1500px.jpg'"
  [title]="title"
></app-project-header>
<app-c16-spacing></app-c16-spacing>
<app-c02-c07-text
  [headline]="'Der Immun&shy;kompass'"
  [headlineLevel]="1"
  [content]="content1"
  [compNumber]="7"
  ><ng-template #content1
    >Wie viele andere Erreger auch, stellt das Corona Virus für die Gesundheit
    einiger Menschen ein hohes Risiko dar. Krampfhaft versuchen wir uns mit
    Abstandsregeln und Hygienemaßnahmen zu schützen und vergessen dabei völlig
    unser eigenes Schutzschild: das Immunsystem. Aber was ist eigentlich das
    Immunsystem und wie kann ich es bewusst stärken? Der Immunkompass klärt
    genau über diese Frage auf und geht dabei gezielt auf eine empfehlenswerte
    Ernährung im Zusammenhang mit der Virenprävention ein.</ng-template
  >
</app-c02-c07-text>
<app-c16-spacing></app-c16-spacing>
<app-video [key]="'512502207'" [compNumber]="12"></app-video>
<app-c16-spacing></app-c16-spacing>
<app-c02-c07-text
  [headline]="'Das Konzept'"
  [headlineLevel]="2"
  [content]="content2"
  [compNumber]="7"
  ><ng-template #content2
    >Unser Konzept beinhaltet eine Website mit Fokus auf eine gesunde Ernährung
    zur Virenprävention, ein Animationsvideo, das über die Funktionsweise
    unserer Abwehrkräfte aufklärt und eine App, die zur täglichen Unterstützung
    beiträgt. <br /><br />Passend zum Thema wurde eine dezente Farbpalette
    zusammengestellt. Aufgrund vieler organischer Formen wurde für
    aussagekräftige Überschriften die Filson Soft ausgewählt. Für kleine
    Überschriften und Fließtext wurde die Fira Sans benutzt, um hier eine gute
    Lesbarkeit zu gewähren. Die Illustrationen des Videos setzen sich aus
    flächigen Formen zusammen, wohingegen die Illustrationen der Website auf
    Scribbles basieren.<br /><br />
    Team: Seraphina Schidlo</ng-template
  >
</app-c02-c07-text>
<app-c16-spacing></app-c16-spacing>
<app-c14-two-images
  [hasSidePaddings]="true"
  [imagePath1]="'/assets/09_Der-Immunkompass/Sources/03.jpg'"
  [title1]="title"
  [imagePath2]="'/assets/09_Der-Immunkompass/Sources/04.jpg'"
  [title2]="title"
></app-c14-two-images>
<app-c16-spacing></app-c16-spacing>
<app-video [key]="'512502509'" [compNumber]="12"></app-video>
<app-c16-spacing></app-c16-spacing>
<app-c11-slider [images]="images"></app-c11-slider>
<app-footer></app-footer>
